<template>
  <div>
    <router-link to="/">
      <img class="logo-size" :src="logo || default_logo" alt="" />
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    logo: String,
  },
  name: "AppLogo",

  data() {
    return {
      default_logo: require("../assets/logo.png"),
    };
  },
};
</script>

<style>
.logo-size {
  height: 50px;
  width: 150px;
  margin-bottom: 20px;
}
</style>
